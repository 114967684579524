import { ReactElement, useEffect, useState } from "react";
import numeral from "numeral";
import PaymentCard from "./PaymentCard";
import axios from "axios";
import { Box, Grid, Typography, Divider } from "@mui/material";
import { Slider, StyledButton as Button } from "../../Custom";
import { useAppDispatch } from "../../../Redux/store";
import { submitLoanInformation } from "../../../Redux/features/Applicant";
import { getReferalCode } from "../../../utils";

// Defining Prop Types for LoanInformation Form
type LoanInformationProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
  setSelected: (value: number) => void;
  setSliderValue: (value: any) => void;
  selected: number;
  sliderValue: number;
};

export default function LoanInformation(
  props: LoanInformationProps
): ReactElement {
  const dispatch = useAppDispatch();
  const referalCode = getReferalCode();
  const [ipAddress, setIPAddress] = useState("");
  const [apr, setApr] = useState<number>(0);

  // IP Address information
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIPAddress(res.data.ip);
  };

  //To Lift scrren to Top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getData();
  }, []);

  // Form Handler
  const submitForm = (requested_amount: number, requested_duration: number) => {
    localStorage.setItem("numberOfPayments", requested_duration.toString());
    localStorage.setItem("apr", apr.toString());
    const id = sessionStorage.getItem("loanApplicantId") || null;
    const ip_address = ipAddress;
    const application_type = referalCode ? "merchant" : "credit";
    requested_amount = requested_amount * 100;
    let params: any = {
      requested_amount,
      requested_duration,
      id,
      applicant_ip: ip_address,
      application_type,
    };
    if (referalCode) {
      params = { ...params, referral_key: referalCode };
    }
    dispatch(submitLoanInformation(params))
      .unwrap()
      .then((data) => {
        sessionStorage.setItem("loanApplicantId", data?.loan_application?.id);
        props.setTabIndex((prev) => prev + 1);
        sessionStorage.setItem("tabValue", "1");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (referalCode) {
      if (props.selected === 6 || props.selected === 3) {
        setApr(0);
      } else {
        setApr(9);
      }
    } else {
      setApr(18);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected]);

  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Adding Typography */}
      <Typography>
        Select {referalCode ? "Financing" : "Loan"} Amount
      </Typography>
      <Typography sx={{ fontSize: "24px", mt: 2 }}>
        $
        <Typography
          sx={{
            color: "#050C1999",
            fontSize: "24px",
            pl: 1,
          }}
          variant="body1"
          component="span"
        >
          {`${numeral(props.sliderValue).format("0,0.00")}`}
        </Typography>
      </Typography>

      {/* Adding Slider */}
      <Slider
        setSliderValue={props.setSliderValue}
        value={props.sliderValue}
        onChange={(event: Event, newValue: number | number[]) => {
          if (typeof newValue === "number") {
            props.setSliderValue(newValue);
            localStorage.setItem("requestedAmount", JSON.stringify(newValue));
          }
        }}
      />

      {/* Add Divider Here */}
      <Divider sx={{ width: "50%", my: 2 }} />

      {/* Payment Options */}
      <Typography sx={{ fontWeight: "bold" }}>
        Select a Payment Option
      </Typography>
      <Grid
        container
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1, mb: 2, width: { sm: "100%", md: "50%" } }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ width: { sm: "100%", md: "18.5rem" } }}
          onClick={() => props.setSelected(referalCode ? 12 : 24)}
        >
          <PaymentCard
            total="000.00"
            months={referalCode ? 12 : 24}
            interest={referalCode ? 9 : 18}
            selected={props.selected}
            sliderValue={props.sliderValue}
            cardKey={referalCode ? 12 : 24}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ width: { sm: "100%", md: "18.5rem" } }}
          onClick={() => props.setSelected(referalCode ? 9 : 18)}
        >
          <PaymentCard
            total="000.00"
            months={referalCode ? 9 : 18}
            interest={referalCode ? 9 : 18}
            selected={props.selected}
            cardKey={referalCode ? 9 : 18}
            sliderValue={props.sliderValue}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ width: { sm: "100%", md: "18.5rem" } }}
          onClick={() => props.setSelected(referalCode ? 6 : 12)}
        >
          <PaymentCard
            total="000.00"
            months={referalCode ? 6 : 12}
            discountedInterest={0}
            interest={referalCode ? 9 : 18}
            selected={props.selected}
            cardKey={referalCode ? 6 : 12}
            sliderValue={props.sliderValue}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ width: { sm: "100%", md: "18.5rem" } }}
          onClick={() => props.setSelected(referalCode ? 3 : 6)}
        >
          <PaymentCard
            total="000.00"
            months={referalCode ? 3 : 6}
            interest={referalCode ? 9 : 18}
            discountedInterest={0}
            selected={props.selected}
            cardKey={referalCode ? 3 : 6}
            sliderValue={props.sliderValue}
          />
        </Grid>
      </Grid>

      {!referalCode && (
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          *Your APR may range from 8% to 18% upon review of your application .
        </Typography>
      )}

      {/* Add Divider Here */}
      <Divider sx={{ width: "50%", my: 2 }} />

      {/* Continue Button */}
      <Button
        variant="contained"
        onClick={() => submitForm(props.sliderValue, props.selected)}
        disabled={props.selected === -1 || props.sliderValue < 100}
      >
        Continue
      </Button>
    </Box>
  );
}
