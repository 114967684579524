import { useMemo, useEffect } from "react";
import { Typography, Grid, Divider, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  InputField,
  StyledButton as Button,
  MaskedInput,
  Select,
} from "../../Custom";
import { ApplicantFormSchema } from "../../../schemas";
import { useAppDispatch, useAppSelector } from "../../../Redux/store";
import { submitApplicantForm } from "../../../Redux/features/Applicant";
import {
  getReferalCode,
  listCountryNames,
  provinceSlugs,
} from "../../../utils";
import ProvinceAutoSelect from "../../Custom/ProvinceAutoSelect";
import useProvinces from "../../../hooks/useProvinces";
import useCountries from "../../../hooks/useCountries";

// Defining Form Props
type ApplicantInformationFormProps = {
  setTabIndex: (updateIndex: (prevIndex: number) => number) => void;
};

export default function ApplicantInformationForm(
  props: ApplicantInformationFormProps
) {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.applicant.loading);
  const applicant = useAppSelector((state) => state.applicant.data);

  //To Lift scrren to Top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // Defining Form Initial Values
  const initialValues = useMemo(
    () => ({
      email: applicant?.email || sessionStorage.getItem("email"),
      phoneNumber: applicant?.phoneNumber || "",
      firstName: applicant?.firstName || "",
      lastName: applicant?.lastName || "",
      dob: applicant?.dob ? new Date(applicant.dob) : "",
      address1: applicant?.address1 || "",
      address2: applicant?.address2 || "",
      country: applicant?.country || "Canada",
      city: applicant?.city || "",
      province: applicant?.province || "",
      postalCode: applicant?.postalCode || "",
      confirmBox: false,
      termsAndConditions: false,
    }),
    [
      applicant?.firstName,
      applicant?.lastName,
      applicant?.email,
      applicant?.dob,
      applicant?.phoneNumber,
      applicant?.address1,
      applicant?.address2,
      applicant?.postalCode,
      applicant?.country,
      applicant?.city,
      applicant?.province,
    ]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: ApplicantFormSchema,
    onSubmit: (values, { resetForm }) => {
      localStorage.setItem('email', values.email);
      const payload = {
        email: values.email,
        phone_number: values.phoneNumber,
        first_name: values.firstName,
        last_name: values.lastName,
        date_of_birth: values.dob,
        loan_application_id: sessionStorage.getItem("loanApplicantId"),
        primary_address: {
          line1: values.address1,
          line2: values.address2,
          country: listCountryNames[values.country],
          city: values.city,
          state: provinceSlugs[values.province],
          zipcode: values.postalCode,
        },
      };
      dispatch(submitApplicantForm(payload))
        .unwrap()
        .then((response) => {
          sessionStorage.setItem(
            "myToken",
            response.data.attributes.authenticityToken
          );
          const address = {
            line1: values.address1,
            line2: values.address2,
            country: values.country,
            city: values.city,
            state: values.province,
            zipcode: values.postalCode,
            first_name: values.firstName,
            last_name: values.lastName,
          };
          sessionStorage.setItem("Address", JSON.stringify(address));
          props.setTabIndex((prev) => prev + 1);
          sessionStorage.setItem("tabValue", "2");
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  });

  const { values, errors, touched, handleChange, handleSubmit } = formik;

  const memoizedProvinces = useProvinces(values.country);
  const memoizedCountries = useCountries();

  const referalCode = getReferalCode();

  return (
    <form style={{ marginTop: "2rem" }} onSubmit={handleSubmit}>
      <Grid container spacing={2} marginBottom={8}>
        <Grid item xs={12} sm={6}>
          <InputField
            label="E-mail"
            placeholder="user@email.com"
            name="email"
            onChange={handleChange}
            helperText={errors.email && touched?.email ? errors?.email : ""}
            hasError={!!(errors?.email && touched?.email)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Phone Number"
            placeholder="xxx-xxx-xxxx"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            helperText={
              errors.phoneNumber && touched?.phoneNumber
                ? errors?.phoneNumber
                : ""
            }
            hasError={!!(errors?.phoneNumber && touched?.phoneNumber)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="First Name"
            placeholder="Jack"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            helperText={
              errors.firstName && touched?.firstName ? errors?.firstName : ""
            }
            hasError={!!(errors?.firstName && touched?.firstName)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Last Name"
            placeholder="Smith"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            helperText={
              errors.lastName && touched?.lastName ? errors?.lastName : ""
            }
            hasError={!!(errors?.lastName && touched?.lastName)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Date of Birth"
            type="date"
            placeholder="Month, Day, Year"
            dateField
            name="dob"
            value={values.dob}
            onChange={handleChange}
            helperText={errors.dob && touched?.dob ? errors?.dob : ""}
            hasError={!!(errors?.dob && touched?.dob)}
            isRequired
          />
        </Grid>
      </Grid>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Home Address
      </Typography>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12} sm={6}>
          <InputField
            label="Address 1"
            placeholder="1234 51 Canada crescent"
            name="address1"
            value={values.address1}
            onChange={handleChange}
            helperText={
              errors?.address1 && touched?.address1 ? errors?.address1 : ""
            }
            hasError={!!(errors?.address1 && touched?.address1)}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label={referalCode ? "Address 2 (Optional)" : "Address 2"}
            placeholder="Apt/Unit/Suite(Optional)"
            name="address2"
            value={values.address2}
            onChange={handleChange}
            helperText={
              errors?.address2 && touched?.address2 ? errors?.address2 : ""
            }
            hasError={!!(errors?.address2 && touched?.address2)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            label="City"
            placeholder="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            isRequired
            helperText={errors?.city && touched?.city ? errors?.city : ""}
            hasError={!!(errors?.city && touched?.city)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProvinceAutoSelect
            name="province"
            label="Province"
            placeholder="Choose Province"
            value={values.province}
            onChange={handleChange}
            hasError={!!(errors?.province && touched?.province)}
            provinces={memoizedProvinces()}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            helperText={
              errors?.province && touched?.province ? errors?.province : ""
            }
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Postal Code"
            placeholder="Postal Code"
            name="postalCode"
            value={values.postalCode.toUpperCase()}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.postalCode && touched?.postalCode
                ? errors?.postalCode
                : ""
            }
            hasError={!!(errors?.postalCode && touched?.postalCode)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label="Country"
            placeholder="Choose Country"
            name="country"
            options={memoizedCountries}
            value={values.country}
            onChange={handleChange}
            hasError={!!(errors?.country && touched?.country)}
            helperText={
              errors?.country && touched?.country ? errors?.country : ""
            }
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            isRequired
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />
      <Grid
        container
        spacing={2}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" disabled={loading} type="submit">
            <Typography> Continue</Typography>
            {loading && (
              <Typography sx={{ ml: 2 }}>
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              </Typography>
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
