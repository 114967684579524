import { useState, useEffect, useMemo, useRef } from "react";
import { grey } from "@mui/material/colors";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Icon,
  CircularProgress,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  Checkbox,
  Select,
  StyledButton,
  MaskedInput,
  InputField,
} from "../../Custom";
import { QuestionIcon } from "../../../assets/svg";
import { ErrorAnimation } from "../../../animation";
import { useAppSelector, useAppDispatch } from "../../../Redux/store";
import { ACCOUNT_TYPES } from "../../../constants";
import {
  submitDebitFormDetails,
  getBankDetails,
} from "../../../Redux/features/Applicant";
import {
  getCaculatedAmount,
  getNumberOfPayments,
  getReferalCode,
  getRequestedAmount,
} from "../../../utils";
import SignatureCanvas from "react-signature-canvas";
import DebitFormSchema from "../../../schemas/DebitInformation";
import theme from "../../../Theme";
import SignPad from "./SignatureCanvas";
import Agreement from "../../Referal/Agreement";
import "./index.css";


// Defining Form Value Interface
interface FormValues {
  bankNumber: string;
  accountType: string;
  accountNumber: string;
  transitNumber: string;
  jointAccount: boolean;
  signature: string;
  interval: string;
  confirmAgreement: boolean;
  amount: number;
  financedAmount: number;
  numberOfPayments: number;
  merchantName: string;
}

type DebitFormInformationProps = {
  detailsDialog: boolean;
  handleDetailsDialog: () => void;
  onSubmit: () => void;
  agree: boolean;
  setOpen: any;
  setAgree: (value: any) => void;
};

export default function DebitInformationForm(props: DebitFormInformationProps) {
  const [signaturePad, setSignaturePad] = useState(false);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.applicant.loading);
  const applicant = useAppSelector(
    (state) => state.applicant?.data?.attributes
  );
  const debitFormInfo = useAppSelector((state) => state.applicant.debitForm);
  const [backDetails, setBankDetails] = useState({
    institution_number: "",
    transit_number: "",
    account_number: "",
  });

  //To Lift scrren to Top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (sessionStorage?.getItem("AccessToken")) {
      dispatch(getBankDetails([]))
        .unwrap()
        .then((response) => {
          setBankDetails(response.bank);
        })
        .catch((err: any) => {
          toast.error(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage?.getItem("AccessToken")]);

  // Memoizing Formik initial values
  const initialValues: FormValues = useMemo(
    () => ({
      bankNumber:
        debitFormInfo?.bankNumber || backDetails.institution_number || "",
      accountType: debitFormInfo?.accountType || "Checking",
      accountNumber:
        debitFormInfo?.accountNumber || backDetails.account_number || "",
      transitNumber:
        debitFormInfo?.transitNumber || backDetails.transit_number || "",
      jointAccount: debitFormInfo?.jointAccount || false,
      signature: "",
      interval: "Monthly",
      confirmAgreement: false,
      amount: getRequestedAmount() || 100,
      financedAmount: getCaculatedAmount(),
      numberOfPayments: getNumberOfPayments(),
      merchantName: applicant?.fullName || "",
    }),
    [
      debitFormInfo?.bankNumber,
      debitFormInfo?.accountType,
      debitFormInfo?.accountNumber,
      debitFormInfo?.transitNumber,
      debitFormInfo?.jointAccount,
      backDetails.account_number,
      backDetails.institution_number,
      backDetails.transit_number,
      applicant?.fullName,
    ]
  );
  const formik = useFormik({
    initialValues,
    validationSchema: DebitFormSchema,
    onSubmit: (values, action) => {
      const payload: any = {
        institution_number: values.bankNumber,
        transit_number: values.transitNumber,
        account_type: values.accountType,
        account_number: values.accountNumber.replace(/_/g, ""),
        loan_application_id: sessionStorage.getItem("loanApplicantId"),
        access_token: sessionStorage.getItem("AccessToken"),
        pad_signature: values.signature,
        pad_signed_at: new Date().toISOString(),
        application_type: referalCode ? "merchant" : "credit",
      };
      setShouldSubmit(true); // Set the flag to indicate that submission should proceed
      props.setOpen(true);
      setPayload(payload); // Set the payload in state
    },
  });

  const padRef = useRef<SignatureCanvas>(null);
  const clearSignature = () => {
    formik.setFieldValue("signature", "");
    formik.setFieldTouched("signature", false);
    padRef?.current?.clear();
  };

  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [payload, setPayload] = useState<any>(null);

  useEffect(() => {
    if (shouldSubmit && props.agree && payload) {
      const submitForm = async () => {
        try {
          await dispatch(submitDebitFormDetails(payload)).unwrap();
          props.onSubmit();
          sessionStorage.removeItem("AccessToken");
          sessionStorage.removeItem("LinkToken");
          sessionStorage.removeItem("Address");
          toast.success("Submitted Details");
        } catch (err: any) {
          toast.error(err);
        } finally {
          props.setAgree(false);
          setShouldSubmit(false); // Reset the flag after submission
        }
      };
      submitForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit, props.agree, payload]);

  useEffect(() => {
    if (sessionStorage?.getItem("AccessToken")) {
      formik.setValues({
        bankNumber: backDetails?.institution_number,
        transitNumber: backDetails?.transit_number,
        accountType: "Checking",
        accountNumber: backDetails?.account_number.replace(/_/g, ""),
        jointAccount: false,
        signature: formik.values.signature || "",
        interval: "Monthly",
        confirmAgreement: formik.values.confirmAgreement || false,
        amount: getRequestedAmount() || 100,
        financedAmount: getCaculatedAmount(),
        numberOfPayments: getNumberOfPayments(),
        merchantName: applicant?.fullName || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backDetails]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const handleSignature = () => {
    setSignaturePad((prev) => !prev);
  };
  const referalCode = getReferalCode();

  return (
    <form style={{ marginTop: "2rem" }} onSubmit={handleSubmit}>
      {/* Displaying Form */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Bank Number"
            placeholder="000"
            name="bankNumber"
            value={values.bankNumber}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.bankNumber && touched?.bankNumber
                ? errors?.bankNumber
                : ""
            }
            hasError={
              !!(
                !props.detailsDialog &&
                errors?.bankNumber &&
                touched.bankNumber
              )
            }
            isDisabled={referalCode ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Transit Number"
            placeholder="Transit Number"
            name="transitNumber"
            value={values.transitNumber}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.transitNumber && touched?.transitNumber
                ? errors?.transitNumber
                : ""
            }
            hasError={
              !!(
                !props.detailsDialog &&
                errors?.transitNumber &&
                touched.transitNumber
              )
            }
            isDisabled={referalCode ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaskedInput
            label="Account Number"
            placeholder="Account Number"
            name="accountNumber"
            value={values.accountNumber}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.accountNumber && touched?.accountNumber
                ? errors?.accountNumber
                : ""
            }
            hasError={
              !!(
                !props.detailsDialog &&
                errors?.accountNumber &&
                touched.accountNumber
              )
            }
            isDisabled={referalCode ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label="Account Type"
            placeholder="Select an option"
            name="accountType"
            options={ACCOUNT_TYPES}
            value={values.accountType || "Checking"}
            onChange={handleChange}
            isRequired
            helperText={
              errors?.accountType && touched?.accountType
                ? errors?.accountType
                : ""
            }
            hasError={(errors?.accountType && touched.accountType) || false}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            isDisabled={referalCode ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Typography sx={{ color: grey[700] }}>
              Please ensure you are the signing authority on this bank account.
            </Typography>

            {!referalCode && (
              <Button
                variant="text"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "none",
                  color: "black",
                  textDecoration: "underline",
                  padding: 0,
                }}
                endIcon={
                  <Icon
                    sx={{
                      width: "25px",
                      height: "25px",
                      color: "blue",
                      ml: 2,
                    }}
                  >
                    <img
                      src={QuestionIcon}
                      alt="Twitter"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Icon>
                }
                onClick={props.handleDetailsDialog}
              >
                How to find PAD Details
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      {/*  Payment Details */}
      <Typography sx={{ fontWeight: "bold", marginTop: 4, marginBottom: 4 }}>
        Payment Details
      </Typography>
      {referalCode ? (
        <>
          <Grid container>
            <Grid container item spacing={1} xs={8} alignItems="center">
              <Grid item xs={6}>
                <Typography className="payment-details">Interval</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label=""
                  placeholder="Monthly"
                  name="interval"
                  onChange={handleChange}
                  // inputProps={{readOnly: true}}
                  helperText={
                    errors.interval && touched?.interval ? errors?.interval : ""
                  }
                  hasError={!!(errors?.interval && touched?.interval)}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={8} alignItems="center">
              <Grid item xs={6}>
                <Typography className="payment-details">Amount</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label=""
                  placeholder="$25"
                  value={`$${formik.values.amount}`}
                  name="amount"
                  onChange={handleChange}
                  // inputProps={{readOnly: true}}
                  helperText={
                    errors.amount && touched?.amount ? errors?.amount : ""
                  }
                  hasError={!!(errors?.amount && touched?.amount)}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={8} alignItems="center">
              <Grid item xs={6}>
                <Typography className="payment-details">
                  Financed Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label=""
                  placeholder="$500"
                  name="financedAmount"
                  value={`$${formik.values.financedAmount}`}
                  onChange={handleChange}
                  // inputProps={{readOnly: true}}
                  helperText={
                    errors.financedAmount && touched?.financedAmount
                      ? errors?.financedAmount
                      : ""
                  }
                  hasError={
                    !!(errors?.financedAmount && touched?.financedAmount)
                  }
                  isDisabled={true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={8} alignItems="center">
              <Grid item xs={6}>
                <Typography className="payment-details">
                  Number Of Payments
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label=""
                  placeholder="12"
                  value={formik.values.numberOfPayments}
                  name="numberOfPayments"
                  onChange={handleChange}
                  // inputProps={{readOnly: true}}
                  helperText={
                    errors.numberOfPayments && touched?.numberOfPayments
                      ? errors?.numberOfPayments
                      : ""
                  }
                  hasError={
                    !!(errors?.numberOfPayments && touched?.numberOfPayments)
                  }
                  isDisabled={true}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={8} alignItems="center">
              <Grid item xs={6}>
                <Typography className="payment-details">
                  Merchant Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputField
                  label=""
                  placeholder="Kai Hearing Aid Solutions Ltd."
                  name="merchantName"
                  value={formik.values.merchantName}
                  onChange={handleChange}
                  // inputProps={{readOnly: true}}
                  helperText={
                    errors.merchantName && touched?.merchantName
                      ? errors?.merchantName
                      : ""
                  }
                  hasError={!!(errors?.merchantName && touched?.merchantName)}
                  isDisabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {" "}
          <Grid container>
            <Grid item xs={6} sm={6} md={6}>
              <InputField
                label="Interval"
                placeholder="Monthly"
                name="interval"
                value={"Monthly"}
                onChange={handleChange}
                // inputProps={{readOnly: true}}
                helperText={
                  errors.interval && touched?.interval ? errors?.interval : ""
                }
                hasError={!!(errors?.interval && touched?.interval)}
                isDisabled={true}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Divider sx={{ my: 4, borderColor: theme.palette.secondary.light }} />

      {/* Terms and Conditions Section */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold" }}>
            {referalCode ? "Financing Agreement" : "Terms and Conditions"}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 2, height: 300, overflowY: "scroll" }}>
          {referalCode ? (
            <Agreement />
          ) : (
            <Typography sx={{ color: grey[700], fontSize: "14px" }}>
              This Agreement is being entered into for our benefit and the
              benefit of any financial institution that holds the PAD Account
              (the "PAD Institution"), and is being entered into in
              consideration of the PAD Institution agreeing to process. <br />{" "}
              <br /> In case the payment amount is fixed, you waive any
              requirement that ONTAB give pre-notification of the payment amount
              except for the first amount, where ONTAB will send you a
              notification 3 days in advance.
              <br /> <br /> In case the payment amount is variable, ONTAB will
              give you a notification of the payment amount at least 10 days in
              advance.
              <br /> <br /> To cancel or change this PAD Agreement, please
              resubmit this application, notify us in writing to
              support@ontab.com or call us on our Toll free number
              1-844-696-6822 or 1-844-MYONTAB at least 5 days before your next
              payment due date. <br /> <br />
              PAD changes and cancellations cannot be made within 5 days of your
              next payment due date. Any changes made within the last 5 days
              before your payment due date will only take effect on your next
              scheduled payment due date. <br /> <br /> Cancelling a
              pre-authorized debit agreement does not cancel your loan agreement
              or any amount owed. The cancellation applies only to the payment
              method. Therefore please make an alternative payment arrangement
              to avoid late payment fees or loan payment default. <br /> <br />
              You have certain recourse rights if any debit does not comply with
              this agreement. For example, you have the right to receive
              reimbursement for any debit that is not authorised or is not
              consistent with this PAD Agreement. To obtain more information on
              your recourse rights, contact your financial institution or visit
              payments.ca. <br /> <br /> If there are insufficient funds in the
              account to cover a withdrawal, ONTAB is authorised to retry the
              debit one more time within 30 days from the date of the withdrawal
              for the exact same amount.
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Signing Section */}
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
            {referalCode
              ? `By signing below, you authorize ONTAB to pay the financed amount to the merchant on your behalf, place an authorization on your credit card for the duration of the loan and withdraw the above mentioned monthly payments from your Canadian Bank Account (the “PAD” Account). You acknowledge that you have read and agree to the Financing Agreement.`
              : `  By signing below, you authorise ONTAB to withdraw the above
            mentioned Loan Payments Amount from your Canadian Bank Account (the
            "PAD" Account). You acknowledge that you have read and agree to the
            Terms and Conditions listed both above and below.`}
          </Typography>
        </Grid>
        {!signaturePad && (
          <Grid item xs={12} sx={{ my: 2 }}>
            <Button
              fullWidth
              variant="text"
              type="submit"
              color="secondary"
              sx={{
                padding: "10px 16px",
                borderRadius: "5px",
                textTransform: "none",
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.secondary.light,
              }}
              onClick={handleSignature}
            >
              Click here to Sign
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sx={{ my: 2 }}>
          {signaturePad && (
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                size="small"
                sx={{ mb: 2 }}
                onClick={clearSignature}
              >
                Clear
              </Button>
              <Box
                sx={{
                  borderColor: theme.palette.secondary.light,
                  animation: `${
                    errors.signature && touched.signature ? ErrorAnimation : ""
                  } 0.5s`,
                  border:
                    errors.signature && touched.signature
                      ? "1px solid #F93A5B"
                      : "2px solid ghostwhite}",
                  borderRadius: "8px",
                }}
              >
                <SignPad
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  validateField={formik.validateField}
                  padRef={padRef}
                />
                <input
                  type="hidden"
                  name="signature"
                  value={values.signature}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* Submitting Form Section */}
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems="baseline"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          [`@media and min-width: ${theme.breakpoints.values.sm}px`]: {
            justifyContent: "end",
            alignItems: "baseline",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={8} sx={{ minWidth: "320px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              mb: 2,
            }}
          >
            <Checkbox
              name="confirmAgreement"
              value={values.confirmAgreement}
              onChange={handleChange}
              hasError={
                !!(errors?.confirmAgreement && touched?.confirmAgreement)
              }
            />
            <Typography
              sx={{
                ml: 1,
                color: grey[700],
                fontSize: 14,
                maxWidth: "100%",
                [`@media and (min-width: 600px)`]: {
                  maxWidth: "80%",
                },
              }}
            >
              {referalCode
                ? "I have read and agreed to the terms and conditions of the Financing Agreement and PAD Agreement."
                : " I have read and agree to the PAD agreement"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <StyledButton variant="contained" type="submit" disabled={loading}>
            Confirm and Apply
            {loading && (
              <Typography sx={{ ml: 2 }}>
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              </Typography>
            )}
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
}
