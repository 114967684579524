import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../api";

// defining Applicant interface
interface Applicant {
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  date_of_birth: Date | string;
  primary_address: {
    line1: string;
    line2: string;
    country: string;
    city: string;
    state: string;
    zipcode: any;
  };
}

// Defining Credit Card Interface
interface CreditCard {
  cardholderName: string;
  cardNumber: string;
  expDateMonth: string;
  expDateYear: string;
  cvv: string;
  address1: string;
  address2: string;
  postalCode: string;
  confirmBox: boolean;
}

// Defining Debit Form interface
interface DebitForm {
  bankNumber: string;
  accountType: string;
  accountNumber: string;
  transitNumber: string;
  jointAccount: boolean;
  confirmAgreement: boolean;
  signature: string;
}

// Login Form Interface
interface LoginForm {
  email: string;
  password: string;
}

//SignUp Form Interface
interface SignupForm {
  email: string;
  password: string;
  confirmPassword: string;
}

// Define the type of initial State
interface ApplicantFormState {
  loading: boolean;
  data: Applicant | {};
  creditCard: CreditCard | {};
  debitForm: DebitForm | {};
  error: string | null;
}

// Defining intitial state
const initialState: ApplicantFormState = {
  loading: false,
  data: {},
  creditCard: {},
  debitForm: {},
  error: "",
};

// Defining Thunks here
export const submitApplicantForm = createAsyncThunk(
  "applicant/post",
  async (data: Applicant, thunkApi) => {
    try {
      const response = await api.post(`/api/profiles`, data);
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  }
);

export const submitLoanInformation = createAsyncThunk(
  "applicant/post",
  async (data: any, thunkApi) => {
    try {
      const response = await api.post(`/api/loan_applications`, data);
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  }
);

export const updateLoanInformation = createAsyncThunk(
  "applicant/put",
  async (data: any, thunkApi) => {
    try {
      const response = await api.put(`/api/loan_applications/${data.loanApplicantId}`, data.payload);
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  }
);

export const submitCreditCardDetails = createAsyncThunk(
  "applicant-credit-card/post",
  async (data: CreditCard, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.post(`/api/credit_cards`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error?.message || "Something went wrong");
    }
  }
);

export const submitDebitFormDetails = createAsyncThunk(
  "applicant-debit-form/post",
  async (data: DebitForm, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.post(`/api/banks`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error_description);
    }
  }
);

export const getPlaidLinkToken = createAsyncThunk(
  "applicant-plaid-link-token/get",
  async (data: [], thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.get(`/api/plaid_link_token`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(
        `Plaid Link Token ${error.response?.statusText}`
      );
    }
  }
);

export const setPlaidAccessToken = createAsyncThunk(
  "applicant-plaid-access-token/post",
  async (data: any, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const response = await api.post(`/api/plaid_public_token`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error_description);
    }
  }
);

export const getBankDetails = createAsyncThunk(
  "applicant-get-Bank-Details/get",
  async (data: any, thunkApi) => {
    try {
      const token: any = sessionStorage.getItem("myToken");
      const accesstoken: any = sessionStorage.getItem("AccessToken");
      const response = await api.get(`/api/banks/:id`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          access_token: accesstoken,
        },
      });
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue(error.response.data.error_description);
    }
  }
);

export const login = createAsyncThunk(
  "applicant/login",
  async (data: LoginForm, thunkApi) => {
    try {
      const response = await api.post(`/api/login/token`, data);
      sessionStorage.setItem("myToken", response.data.access_token);
      const user = await api.post(`/login`, response.data.user.data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${response.data.access_token}`,
        },
      });
      sessionStorage.setItem("email", data.email);
      sessionStorage.setItem("tabVlaue", "0");
      return user;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue("Please Enter Valid Email and Password");
    }
  }
);

export const signUp = createAsyncThunk(
  "applicant/signUp",
  async (data: SignupForm, thunkApi) => {
    try {
      const response = await api.post(`/api/users`, data);
      return response.data;
    } catch (error: any) {
      // Handle any error that occurred during the request
      return thunkApi.rejectWithValue("Error Occured");
    }
  }
);

const applicantSlice = createSlice({
  name: "applicant",
  initialState,
  reducers: {
    clearStore: (state) => ({
      ...state,
      data: {},
      creditCard: {},
      debitForm: {},
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(submitApplicantForm.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(submitApplicantForm.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      data: action.payload.data,
    }));
    builder.addCase(submitApplicantForm.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: "Something went wrong",
    }));
    builder.addCase(submitCreditCardDetails.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(submitCreditCardDetails.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      creditCard: action.payload.data,
    }));
    builder.addCase(submitCreditCardDetails.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: "Something went wrong",
    }));
    builder.addCase(submitDebitFormDetails.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(submitDebitFormDetails.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      debitForm: action.payload.data,
    }));
    builder.addCase(submitDebitFormDetails.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: "Something went wrong",
    }));
    builder.addCase(login.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(login.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      data: { ...state.data, ...action.payload.data },
    }));
    builder.addCase(login.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: "Something went wrong",
    }));
  },
});

export const { clearStore } = applicantSlice.actions;
export default applicantSlice.reducer;
